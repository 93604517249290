import React from "react"
import Header from "./header"
import Footer from "./footer"
import Explore from "./explore"

function extractImages(pageContext) {
  const images = []
  for (let i = 1; i <= 10; i++) {
    const image = pageContext['image' + i]
    if (image && image.url && image.size > 0) {
      images.push(image.url)
    }
  }
  return images
}

const FisherPage = ({ pageContext }) => (
  <div>
    <Header/>

    <div id="splash">
      <img src={pageContext.splash && pageContext.splash.url}/>
      <h1 className="content-pad">{pageContext.name}</h1>
    </div>

    <div className="content-pad">
      <div className="columns-2 big-margin-bottom">
        {pageContext.biography_it ? <p>{pageContext.biography_it}</p> :  <p>{pageContext.biography}</p>}
        <p>
          <span className="details">
            {pageContext.address ? <b>Indirizzo</b> : null}
            {pageContext.address ? <span>{pageContext.address}</span> : null}
            {pageContext.mobile ? <b>Telefono</b> : null}
            {pageContext.mobile ? <span>{pageContext.mobile}</span> : null}
            {pageContext.fax ? <b>Fax</b> : null}
            {pageContext.fax ? <span>{pageContext.fax}</span> : null}
            {pageContext.email ? <b>Email</b> : null}
            {pageContext.email ? <a target="_blank" href={'mailto:'+pageContext.email}>{pageContext.email}</a> : null}
            {pageContext.activities ? <b>Attività</b> : null}
            {pageContext.activities ? <span>{pageContext.activities.join(', ')}</span> : null}
            {pageContext.website ? <b>Sito</b> : null}
            {pageContext.website ? <a target="_blank" href={pageContext.website}>{pageContext.website}</a> : null}
            {pageContext.facebook ? <b>Facebook</b> : null}
            {pageContext.facebook ? <a target="_blank" href={'https://facebook.com/'+pageContext.facebook}>{pageContext.facebook}</a> : null}
            {pageContext.instagram ? <b>Instagram</b> : null}
            {pageContext.instagram ? <a target="_blank" href={'https://instagram.com/'+pageContext.instagram}>{pageContext.instagram}</a> : null}
            {pageContext.twitter ? <b>Twitter</b> : null}
            {pageContext.twitter ? <a target="_blank" href={'https://twitter.com/'+pageContext.twitter}>{pageContext.twitter}</a> : null}
          </span>
        </p>
      </div>

      <h2>Gallery</h2>
      <div id="gallery" className="big-margin-bottom">
        {extractImages(pageContext).map(imageUrl => (
          <a href={imageUrl} target="_blank"><img src={imageUrl}/></a>
        ))}
      </div>

      <h2>Esplora nella stessa zona</h2>
    </div>
    <Explore location={pageContext.location} slug={pageContext.slug}/>

    <Footer/>
  </div>
)

export default FisherPage
